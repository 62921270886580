.featured-container {
  text-align: center;
  overflow: hidden;
}

.bgimage1{
  position: absolute;
  width: 100vw;
}

#whyCA{
  height: 100vh;
  background-image: url("../../Assests/circles-01.png");
  background-attachment: fixed;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.transpantlayer{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10vh 2%;
  /* background-color:rgba(171, 26, 26, 0.5); */
}
.listpt{
  text-align: left;
  /* border: 1px solid rgb(231, 226, 226); */
  border-radius: 5px 20px;
  margin: 10px auto;
  width: 80%;
  color: rgb(245, 245, 245);
  padding: 20px;
  background-color: rgb(85, 85, 85);
  -webkit-box-shadow: 13px 12px 5px -10px rgba(26, 107, 161, 0.72);
  -moz-box-shadow: 13px 12px 5px -10px rgba(0, 0, 0, 0.72);
  box-shadow: 12px 13px 6px -10px rgba(10, 7, 10, 0.72);
}
#whyyHead
{
  font-weight: 600;
}
.listpt:hover{
  cursor: none;
  border-color: aqua;
  border-width: 1px;
}

#parallex {
   display: flex;
   flex-wrap: nowrap;
   width: 400vw;
}

.responsibilities {
  width: 80vw;
  padding: 2vh 10vw;
  position: relative;
  display: flex !important;
  justify-content: center;
  flex-direction: column;
  text-align: left;
  color: var(--bg-color-dark);
}
.headingres{
  color: rgb(161, 190, 234);
}
.whyheading
{
  color: white;
}

.responsibilities:nth-child(1) {
  text-align: center;
  top: 0%;
}

.responsibilities .img-advertise{
  position: relative;
  height: 40vh;
  z-index: -1;
  right: 20%;
  border-radius: 20px;
  -webkit-box-shadow: 15px 15px 15px 2px rgba(65, 64, 64, 0.72);
  -moz-box-shadow: 15px 15px 15px 2px rgba(65, 64, 64, 0.72);
  box-shadow: 15px 15px 15px 2px rgba(65, 64, 64, 0.72);
}

.pin-spacer {  /* background behind responsibility */
  background-image: url("../../Assests/gallery-01.jpg");
  background-attachment: fixed;
  background-size: cover;
}


@media screen and (max-width:980px) {
  #parallex{
    display: flex;
    width: 100vw;
    flex-direction: column;
    align-items: center;
    background-image: url("../../Assests/gallery-01.jpg");
    background-attachment: fixed;
    background-size: cover;

  }
  .responsibilities .img-advertise{
    height: 20vh;
    right: 20%;
    margin: 10%;
  }
}

