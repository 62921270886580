#gallery
{
  background-image: url("../../Assests/contact\ us-01.png");
  background-attachment: fixed;
  background-size: cover; 
  background-blend-mode: darken;
  padding: 5% 0;
}
.heading {
  font-size: xx-large;
  font-weight: bold;
  align-items: center;
  margin: 1rem;
  display: flex;
  justify-content: center;
}
.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  transition: ease-in;
}

.design {
  background-color: black;
  position: relative;

  border: transparent;

  border-width: 4px;
  /* box-shadow: 2px 10px 20px 15px rgba(0, 0, 0, 0.2); */
  /* box-shadow: 12px 13px 6px -10px rgba(128, 67, 128, 0.72); */
  border-radius: 20px 20px 20px 20px;
}
#text {
  width: 65%;
  color: white;
  text-align: center;
  font-size: 30px;
  border-radius: 0px 0px 20px 20px;
  font-family: "Times New Roman", Times, serif;
  background-color: transparent;
  background-image: linear-gradient(
    180deg,
    rgba(249, 249, 249, 0) 0%,
    rgba(0, 0, 0, 0.7917760854341737) 67%
  );
  margin: auto;
  position: relative;
  height: 100px;
  top: -100px;
}
#gallery-heading
{
  font-weight: 700;
  margin: 8px;
}
#year {
  color: white;
  display: flex;
  justify-content: start;
  width: 65%;
  font-family: "Times New Roman", Times, serif;
  background-color: rgb(0, 0, 0);
  margin: auto;
}
.slider {
  display: flex;
  margin: auto;
  justify-content: center;
  vertical-align: middle;
}

.slide {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 1000px) {
  .design {
    width: 98%;
    background: none;
    border-radius: 10px;
  }
  #text {
    width: 100%;
    border-radius: 0px;
    font-size: 25px;
  }
  .slider {
    width: 100%;
    height: 55vh;
    margin-top: 0;
  }
  .slide {
    width: 100%;
  }

  #gallery {
    height: fit-content !important;
  }

  .awssld__container {
    width: 100% !important;
    margin: auto;
  }
  .awssld {
    --slider-height-percentage: 70% !important;
  }
}

@media (min-width: 1000px) {
  .slider {
    height: 85vh;
    width: 80%;
  }
  .design {
    width: 100%;
  }

  .awssld__container {
    width: 60% !important;
    height: 100% !important;
    margin: auto;
  }

  .awssld {
    --slider-height-percentage: 42% !important;
  }
}
@media (max-width: 500px) {
  .slider {
    height: 35vh;
    width: 95%;
  }
  .design {
    width: 98%;
    border: none;
  }
  .slide {
    width: 100%;
  }

  .awssld {
    --slider-height-percentage: 55% !important;
  }

  #gallery {
    height: fit-content !important;
    /* margin: 12% 0; */
  }

  .awssld__bullets {
    display: none !important;
  }
}

.awssld__content {
  background-color: transparent !important;
  height: 100% !important;
}

.awssld__wrapper {
  margin: auto !important;
}

.images {
  width: 97%;
}
.awssld__bullets {
  bottom: -30px !important;
}
