@media (min-width: 1200px) {
  @import url('./navbar.css');
}

@media (max-width: 1200px) {
  * {
      box-sizing: border-box;
  }

  body {
      margin: 0px;
      font-family: 'segoe ui';
  }

  .nav {
      height: 50px;
      width: 100%;
      background-color: #4d4d4d;
      position: relative;
      z-index: 1;
  }

  .nav > .nav-header {
      display: inline;
  }

  .nav > .nav-header > .nav-title {
      font-size: 15px;
      color: #fff;
      padding: 10px 10px 10px 10px;
  }

  .nav > .nav-header > .nav-title img {
      margin-right: 10px !important;
  }

  .nav > .nav-btn {
      display: none;
  }

  .nav > .nav-links {
      display: inline;
      float: right;
      font-size: 14px;
  }

  .nav > .nav-links > a {
      display: inline-block;
      padding: 5px 5px 5px 5px;
      text-decoration: none;
      color: #efefef;
      transition: background-color 0.3s, color 0.3s;
  }

  .nav > .nav-links > a:hover {
      background-color: rgba(0, 0, 0, 0.3);
      color: #fff;
  }

  .nav > #nav-check {
      transition: all 0.3s;
      display: none;
  }

  @media (max-width: 850px) {
      .nav > .nav-btn {
          display: inline-block;
          position: absolute;
          right: 0px;
          top: 0px;
      }

      .nav > .nav-btn > label {
          display: inline-block;
          width: 50px;
          height: 50px;
          padding: 13px;
      }

      .nav > .nav-btn > label:hover,
      .nav #nav-check:checked ~ .nav-btn > label {
          background-color: rgba(0, 0, 0, 0.3);
      }

      .nav > .nav-btn > label > span {
          display: block;
          width: 25px;
          height: 10px;
          border-top: 2px solid #eee;
      }

      .nav > .nav-links {
          position: absolute;
          display: block;
          width: 100%;
          background-color: #333;
          height: 0px;
          transition: all 0.3s ease-in;
          overflow-y: hidden;
          top: 50px;
          left: 0px;
      }

      .nav > .nav-links > a {
          display: block;
          width: 100%;
      }

      .nav > #nav-check:not(:checked) ~ .nav-links {
      transition: all 0.3s;

          height: 0px;
      }

      .nav > #nav-check:not(:checked) ~ .toggler {
      transition: all 0.3s;

          display: none;
      }

      .nav > #nav-check:checked ~ .nav-links {
      transition: all 0.3s;

          height: fit-content;
          min-height: fit-content;
          overflow-y: auto;
      }
  
      .reg {
          background-color: #665700;
          box-sizing: border-box;
          border-radius: 38px;
          margin: 5px;
          padding: 5px 0px !important;
          width: 45% !important;
          margin: auto;
          margin-top: 5px;
      }
  
      .toggler {
          position: absolute;
          width: 100%;
          height: 100vh;
          z-index: -1;
      }

      @media (min-width: 700px) {
          .nav > .nav-links {
              display: inline;
              float: right;
              font-size: 18px;
          }

          .nav > #nav-check:checked ~ .nav-links {
      transition: all 0.3s;

              height: calc(43vh);
              min-height: fit-content;
              overflow-y: auto;
          }
      }
  }
}

@media (prefers-color-scheme: dark) {
  .dark\:text-gray-400 {
      --tw-bg-opacity: 1;
      background-color: #009879 !important;
      color: #333;
  }
}

.nav-header {
  width: 100%;
}

.nav-title {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  font-weight: 750;
  justify-content: center;
  font-size: 25px;
}

.nav-title img {
  width: 33px;
  margin: 0px;
  margin-right: 12px;
}

.nav-title span {
  color: red;
}

/* Add hover effect for buttons and links */
.switchpage:hover {
  background-color: #767676; /* Darker blue on hover */
  color: #fff; /* White text color on hover */
}

/* Additional styles for buttons and links */
.switchpage {
  width: 100%;
  background-color: #b9b9b9; /* Blue color */
  color: #fff; /* White text color */
  padding: 8px 16px; /* Adjusted padding */
  border: 2px solid #b7b7b7; /* Blue border */
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px; /* Adjusted font size */
  margin: 5px 0;
  text-transform: uppercase;
}
.linksm:hover {
  background-color: #494949; /* Darker blue on hover */
  color: #fff; /* White text color on hover */
}
/* Hover effect for checkboxes */

/* Dark mode color adjustments */
.dark\:text-gray-400 {
  --tw-bg-opacity: 1;
  background-color: #009879 !important;
  color: #333;
}

/* Your existing CSS below this line... */

