
.navbar-container{
    color: white;
    display: flex;
    flex-direction: row;
    justify-content:center;
    z-index: 2;
    width: 100vw;
    height: 60px;
    position: fixed;
}
.nav1{
    position: absolute;
    height: 100%;
    width: 65%;
    z-index: -1;
    border-radius: 0 0 20px 20px;
    background-color: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(10px) invert(20%);
    transform: perspective(800px) rotateX(-45deg) scale(1,1.4);
    border-radius: 0 0 20px 20px;
}

.Nav-components
{
    display: flex;
    flex-direction: row;
    justify-content: center;
    top: 0;
    position: absolute;
    height: 100%;
    width: 100%;
    align-items: center;
}
.Nav-in{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 60%;
    margin: 8%;
}

.navlink{
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 0.8%;
    width: 120px;
    font-size: 1.2rem;
    font-weight: 500;
}
.nav-logo{
    position: absolute;
    left: 5%;
    top: 10%;
}
.nav-reg{
    height: 43px;
    position: absolute;
    right: 5%;
    top: 35%;
    width: 100px;
    background-image: linear-gradient(to right, #DC2424 0%, #4A569D  51%, #DC2424  100%);
    font-size: 17px;
    border-radius: 10px;
    align-items: center;
    transition: 0.5s;
    text-align: center;
    background-size: 200% auto;
    color: rgba(255, 255, 255, 0.679);            

}

.nav-reg:hover {
    background-position: right center; 
    color: #fff;
    text-decoration: none;
}

@media only screen and (max-width: 768px)
{
    .nav1{
        height: 80px;
        width: 100%;
        transform: none;

    }
    .Nav-in{
        display: none;
    }
    .nav-logo{
        position: relative;
        left: 0;
    }
    .registerbtn{
        display: none;
    }
    .nav-reg{
        position: relative;
        right: 0;
    }
}