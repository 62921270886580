#testimonials {
  background-image: url("../../Assests/gallery-01.jpg");
  background-attachment: fixed;
  background-size: cover;
  background-blend-mode: darken;
  padding: 5% 0;
}
#gallery-heading {
  font-weight: 700;
  margin: 8px;
}
.slider {
  display: flex;
  margin: auto;
  justify-content: center;
  vertical-align: middle;
}

.slide {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (min-width: 1000px) {
  .slider {
    width: 80%;
  }

  #testimonials .awssld__container {
    width: 60% !important;
    height: 100% !important;
    margin: auto;
  }

  #testimonials .awssld {
    --slider-height-percentage: 33% !important;
  }

  .testi {
    width: 70vw;
    background-color: rgba(255, 255, 255, 0.363);
    border-radius: 15px;
    display: flex;
    padding: 30px 20px;
    flex-direction: row;
    min-height: fit-content;
    height: 42vh;
  }
 
  .img_contain {
    height: 200px;
    width: 200px;
    justify-content: center;
    /* overflow: hidden; */
    align-items: center;
  }
  .testim_image {
    /* transform: scale(1.3); */
    height: 80%;
    width: 80%;
    border-radius: 500px;
  }
  .testi_desc {
    position: relative;
    margin: auto 23px;
    width: 70%;
    /* font-size: 10px; */
    /* text-align: right; */
    text-align: center;
  }
  .testi p{
    font-size: 1.25rem;
  }

  .img_contain p{
    font-size: 1.4rem;
    font-weight: bold;
    margin-top: 5px;
  }
  #testimonials .awssld__content {
    background-color: transparent !important;
    height: 100% !important;
  }
}
@media (max-width: 1000px) and (min-width: 500px) {
  .testim_image {
    width: 8rem;
    height: 8rem;

    border-radius: 500px;
    background: none;
    /* border-radius: 10px; */
  }
  #text {
    width: 100%;
    border-radius: 0px;
    font-size: 25px;
  }
  .slider {
    width: 100%;
    height: 55vh;
    margin-top: 0;
  }
  .slide {
    width: 100%;
  }

  #testimonials {
    height: fit-content !important;
  }

  #testimonials .awssld__container {
    width: 100% !important;
    margin: auto;
  }
  #testimonials .awssld {
    --slider-height-percentage: 45% !important;
  }

  .testi {
    width: 95vw;
    background-color: rgba(255, 255, 255, 0.363);
    border-radius: 15px;
    display: flex;
    padding: 45px 20px;
    flex-direction: row;
    height: fit-content;
    
  }
 
  .img_contain {
    height: 200px;
    width: 200px;
    justify-content: center;
    /* overflow: hidden; */
    align-items: center;
  }
  .testim_image {
    /* transform: scale(1.3); */
    height: 80%;
    width: 80%;
    border-radius: 500px;
  }
  .testi_desc {
    position: relative;
    margin: auto 23px;
    width: 70%;
    /* font-size: 10px; */
    /* text-align: right; */
    text-align: center;
  }
  .testi p{
    font-size: 1.25rem;
  }

  .img_contain p{
    font-size: 1.4rem;
    font-weight: bold;
    margin-top: 5px;
  }
}

@media (max-width: 500px) {
  .slider {
    height: 50vh;
    width: 95%;
  }
  .testim_image {
    width: 6rem;
    height: 6rem;
    border-radius: 500px;
    border: none;
  }
  .slide {
    width: 100%;
  }

  #testimonials .awssld {
    --slider-height-percentage: 85% !important;
  }

  #testimonials {
    height: fit-content !important;
    /* margin: 12% 0; */
  }

  #testimonials .awssld__bullets {
    display: none !important;
  }
  .testi_desc {
    font-size: 13px;
    position: relative;
    margin: auto;
  }

  .testi {
    width: 95%;
    background-color: rgba(255, 255, 255, 0.363);
    border-radius: 25px;
    /* display: flex; */
    padding: 23px;
    min-height: fit-content;
    height: 50vh;
  }
  /* .img_contain {
    display: contents;
    border-radius: 500px;
  } */
  .testi p{
    font-size: 14px;
  }

  .img_contain p{
    /* font-size: 1.4rem; */
    font-weight: bold;
  }
}

#testimonials .awssld__content {
  background-color: transparent !important;
  height: 100% !important;
}

#testimonials .awssld__wrapper {
  margin: auto !important;
}

#testimonials .awssld__bullets {
  display: none;
  bottom: -30px !important;
}

#testimonials .awssld__timer {
  /* display: none; */
  color: transparent;
  background-color: transparent;
}
