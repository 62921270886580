.header-container{
  background-image: url("./bg.png");
  background-attachment: fixed;
  color: rgb(255, 255, 255);
  background-position-x:center;
  background-position-y: bottom; 
  background-size: cover ;
}
.backblur{
  position: relative;
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  background-color:rgba(34, 34, 34, 0.4);
}

.header-menu{
  position: absolute;
  left: 0;
  top: 100px;
  letter-spacing: 1px;
  font-family: 'Syncopate', sans-serif;
  color: #626262;
  font-weight: 600;
  text-transform: uppercase;
  
}

.header-menu li {
  margin: 10px 0;
}

.header-text{
  text-transform: uppercase;
  text-align: center;
  color: white;
  font-family: 'Bai Jamjuree', sans-serif;
  font-weight: 600;
 
}

.lineParent{
  overflow: hidden;
  
}

.lineChildren{
  transform: translate(0, 500px);
}

.header-panel{
  position: relative;
  display: flex;
  /* top: -90px; */
  flex-direction: column;
  justify-content: center;
  text-align: center;
  width: 100%;
  ;
}

.header-panel-foo{
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 50px;
  word-spacing: 10px;
}
.foo-middle{
  transform: scale(0,0);
  padding: 10px;
}
#foo-left{
  opacity: 0;
  padding: 10px;
}
#foo-right{
  opacity: 0;
  padding: 10px;
}
.udghosh-span{
  color: rgb(161, 190, 234);
}
.partition{
  opacity: 0;
  height: 40%;
  border-left: solid 1px rgb(255, 255, 255);
  width: 1px;
}
.partners{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.partnersleft
{
  position: relative;
  margin-right: 320px;
}

.CA-logo {
  display: flex;
  justify-content: center;
  align-items: end;
  align-content: center;
}

.CA-logo > a {
  border-radius: 5px;
  overflow: hidden;
  margin: 0px 10px;
  color: black;
}
.CA-logo > a > img{
  height: 40px;
}

.CA-logo > div > p {
  display: flex;
  align-items: end;
  height: 10vh;
  font-size: medium;
  font-style: italic;
}

.represent-college {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: rgb(161, 190, 234);
  z-index: 1;
  font-size: 25px;
  font-weight: 400;
}
.textHL
{
  font-weight: 600;
}

.in-festival {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: -1;
  height: 10vh;
}

.in-festival > div {
  font-size: 20px;
  color: var(--bg-color-mid1);

}
h1{
  font-size: 4rem;
  font-weight: 700;
}
h3{
  font-size: 3.2rem;
  font-weight: 600;
}
h5{
  font-weight: 500;
  font-size: 2.4rem;
}

p{
  font-size: 1.4rem;
}

@media (max-width: 1250px){
  h1{
    font-size: 3.2rem;
    font-weight: 700;
  }
  h3{
    font-size: 2.8rem;
    font-weight: 600;
  }
  h5{
    font-weight: 500;
    font-size: 2.4rem;
  }

  p{
    font-size: 1.2rem;
  }
  .header-panel-foo{
    font-size: 13px;
  }
  .represent-college > div {
    font-size: 14px;
  }
}

@media (max-width: 750px) {
  .partnersleft
  {
    margin-right: 150px;
  }
  .CA-logo > a > img{
    height: 30px;
  }
  h1{
    font-size: 2.8rem;
    font-weight: 700;
  }
  h3{
    font-size: 2.4rem;
    font-weight: 600;
  }
  h5{
    font-weight: 500;
    font-size: 2rem;
  }

  p{
    font-size: 1.2rem;
  }
  .header-container{
    display: flex;
    align-items: center;
  }


  .represent-college {
    flex-wrap: wrap;
    margin: 5px 15px;
  }
  .represent-college > div {
    font-size: 10ox;
  }
}
@media (max-width: 550px) {
  .partnersleft
  {
    margin-right: 100px;
  }
  .CA-logo > a > img{
    height: 22px;
  }
  h1{
    font-size: 1.8rem;
    font-weight: 600;
  }
  h3{
    font-size: 1.6rem;
    font-weight: 500;
  }
  h5{
    font-weight: 450;
    font-size: 1.4rem;
  }

  p{
    font-size: 0.8rem;
  }
}