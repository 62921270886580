.profile-section{
  background-image: url("../../Assests/contact us-01.png");
  background-size: cover;
  background-attachment: fixed;
  transition: all 2s;
}

.sectionHeading{
  position: relative;
  font-size: 2.5rem;
  color: #000000;
  text-align: center;
  margin: 50px 0 0 0;
  padding: 10px 0 0 0;
}

.ContactUs{
    padding: 20px;
    margin: 0 auto;
    text-align: center;
    font-family: 'Raleway', sans-serif;
    width: 100%;
    display: block;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.cards{
  display: flex;
  justify-content: center;
  padding: 0;
  flex-wrap: wrap;
}

.Profile-card{
    position: relative;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
    width: 240px;
    margin: 5% 6vw;
    transition: 0.3s ease-in-out;
}

.imgBx{
  transition: 0.3s ease-in-out;
  position: relative;
  top: 0;
  overflow: hidden;
  border-radius: 5%;
  position: absolute;
  width: 85%;
  z-index: 1;
  box-shadow:0 0 6px rgba(128, 28, 139, 0.73);
}

.Profile-card:hover{
  scale: 1.05;
}
.cards .Profile-card .Card-content{
  margin: 10% 0 0 0;
  padding: 80% 0 0 0;
  width: 100%;
  position: relative;
  transition: 0.3s ease-in-out;
  box-shadow: 1px 1px 3px 0px rgba(155, 155, 155, 0.75);
  transition: 0.3s ease-in-out;
  border-width: 1px;
  border-color: #9097a0;
  border-radius: 10px;  
  background-color: rgba(236, 236, 236, 0.75);
  backdrop-filter: blur(10px);
  
}
.Profile-card:hover .Card-content{
  box-shadow: 3px 3px 10px 0px rgba(0,0,0,0.75);

}
.imgBx:hover{
  scale: 1.06;
  border-color:rgb(128, 66, 131);
  border-width: 1px;
}
.card-name{
  font-size: 1.5rem;
  font-weight: 400;
  display: block;
 }
 
.cards .credentials{
  font-size: 0.8rem ;
  padding: 0;
  margin: 0;
  font-weight: 500;
}

.social_icons{
  width: 100%;
  height: 100%;
  padding: 2%;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  transition: 0.3s ease-in-out;
}

.social_icons li{
  padding: 0 5%;
}
.social_icons li a .Icons {
  transition: 0.3s;
}
.social_icons li:hover .Icons{
  transform: rotate(30deg) translateY(-3px);
  transform-origin: center;
  color: rgb(65, 87, 141);
  cursor: pointer;
}

@media (max-width: 675px) {

  .Profile-card{
    width: 160px;
    
  }
  .Profile-card .Card-content .card-name{
    font-size: 20px;
  }
  .sectionHeading{
    font-size: 30px;
  }

}